/* globals document window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ViewHeader from '../../components/_view-header';
import TransactionHistoryTable from '../../components/_table/transaction-history-table';
import Search from '../../components/_search';
import ExportTransfersModal from '../../components/_modals/export-transfers';
import ExportTransactionsModal from '../../components/_modals/export-transactions';
import ExportMarginTransactionsModal from '../../components/_modals/export-margin-transactions';
import ExportOptionTransactionsModal from '../../components/_modals/export-option-transactions';
import ExportFTXReportModal from '../../components/_modals/export-ftx-report';

export default class TransactionHistoryView extends Component {
  static propTypes = {
    isTransfersModalOpen: PropTypes.bool,
    openTransfersModal: PropTypes.func,
    closeTransfersModal: PropTypes.func,

    isTransactionsModalOpen: PropTypes.bool,
    openTransactionsModal: PropTypes.func,
    closeTransactionsModal: PropTypes.func,

    isMarginTransactionsModalOpen: PropTypes.bool,
    openMarginTransactionsModal: PropTypes.func,
    closeMarginTransactionsModal: PropTypes.func,

    isOptionTransactionsModalOpen: PropTypes.bool,
    openOptionTransactionsModal: PropTypes.func,
    closeOptionTransactionsModal: PropTypes.func,

    isFtxReportModalOpen: PropTypes.bool,
    openFtxReportModal: PropTypes.func,
    closeFtxReportModal: PropTypes.func,

    setSearchValue: PropTypes.func,
    setTrim: PropTypes.func,
    loadMoreData: PropTypes.func,
    isFetching: PropTypes.bool,
    shouldShowButton: PropTypes.bool,
    transactions: PropTypes.arrayOf(PropTypes.shape()),
    metadata: PropTypes.arrayOf(PropTypes.shape()),
    accounts: PropTypes.arrayOf(PropTypes.shape()),
    startDate: PropTypes.shape(),
    isCSVFetching: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { shouldShowButton, setTrim } = this.props;
    let contHeight = document.getElementById('scroll-container');
    contHeight = contHeight && contHeight.clientHeight;
    if (
      shouldShowButton &&
      window.scrollY + window.innerHeight > contHeight + 200
    ) {
      setTrim();
    }
  }

  renderLoader = () => {
    const { isCSVFetching } = this.props;
    if (isCSVFetching) {
      return (
        <div style={{ marginLeft: 25 }}>
          <div
            className="sk-spinner sk-spinner-chasing-dots"
            style={{ width: 25, height: 25 }}
          >
            <div className="sk-dot1" />
            <div className="sk-dot2" />
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      isTransfersModalOpen,
      openTransfersModal,
      closeTransfersModal,

      isTransactionsModalOpen,
      openTransactionsModal,
      closeTransactionsModal,

      isMarginTransactionsModalOpen,
      openMarginTransactionsModal,
      closeMarginTransactionsModal,

      isOptionTransactionsModalOpen,
      openOptionTransactionsModal,
      closeOptionTransactionsModal,

      isFtxReportModalOpen,
      openFtxReportModal,
      closeFtxReportModal,

      transactions,
      setSearchValue,
      shouldShowButton,
      loadMoreData,
      isFetching,
      accounts,
      metadata,
      startDate,
      isCSVFetching
    } = this.props;
    return (
      <div>
        {isTransfersModalOpen ? (
          <ExportTransfersModal close={closeTransfersModal} />
        ) : null}
        {isTransactionsModalOpen ? (
          <ExportTransactionsModal close={closeTransactionsModal} />
        ) : null}
        {isMarginTransactionsModalOpen ? (
          <ExportMarginTransactionsModal
            close={closeMarginTransactionsModal}
            accounts={accounts}
          />
        ) : null}
        {isOptionTransactionsModalOpen ? (
          <ExportOptionTransactionsModal close={closeOptionTransactionsModal} />
        ) : null}
        {isFtxReportModalOpen ? (
          <ExportFTXReportModal
            close={closeFtxReportModal}
            metadata={metadata}
          />
        ) : null}
        <ViewHeader title="Transaction history" />
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <ul
                  className="nav nav-tabs"
                  style={{ marginBottom: 10, border: 'none' }}
                >
                  <li className="pull-left">{this.renderLoader()}</li>
                  <li className="pull-right">
                    <button
                      onClick={() => openTransfersModal()}
                      type="button"
                      className="btn btn-primary btn-margin-right"
                      disabled={isCSVFetching}
                    >
                      <i className="fa fa-download" />
                      Transfers
                    </button>
                    <button
                      onClick={() => openTransactionsModal()}
                      type="button"
                      className="btn btn-primary btn-margin-left"
                      disabled={isCSVFetching}
                    >
                      <i className="fa fa-download" />
                      Transactions
                    </button>
                    <button
                      onClick={() => openMarginTransactionsModal()}
                      type="button"
                      className="btn btn-primary btn-margin-left"
                      disabled={isCSVFetching}
                    >
                      <i className="fa fa-download" />
                      Margin transactions
                    </button>
                    <button
                      onClick={() => openOptionTransactionsModal()}
                      type="button"
                      className="btn btn-primary btn-margin-left"
                      disabled={isCSVFetching}
                    >
                      <i className="fa fa-download" />
                      Option transactions
                    </button>
                    <button
                      onClick={() => openFtxReportModal()}
                      type="button"
                      className="btn btn-primary btn-margin-left"
                      disabled={isCSVFetching}
                    >
                      <i className="fa fa-download" />
                      Export FTX Report
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <div className="panel-body">
                      <div className="row">
                        <div className="grid-2">
                          <div>
                            <Search
                              onChange={e => {
                                if (e.length < 3) {
                                  setSearchValue('');
                                } else {
                                  setSearchValue(e);
                                }
                              }}
                              size={8}
                            />
                            {isFetching ? (
                              <div
                                style={{
                                  display: 'inline-block',
                                  marginLeft: 20,
                                  verticalAlign: 'middle'
                                }}
                              >
                                <div
                                  className="sk-spinner sk-spinner-chasing-dots"
                                  style={{ width: 25, height: 25 }}
                                >
                                  <div className="sk-dot1" />
                                  <div className="sk-dot2" />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <hr />
                      {transactions && transactions.length !== 0 ? (
                        <TransactionHistoryTable transactions={transactions} />
                      ) : (
                        !isFetching && (
                          <h3
                            style={{
                              textAlign: 'center',
                              padding: 10,
                              margin: 10
                            }}
                          >
                            <b>No transactions found</b>
                          </h3>
                        )
                      )}
                      {shouldShowButton ? null : (
                        <div className="load-more-info">
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled={isFetching}
                            onClick={() => {
                              loadMoreData();
                            }}
                          >
                            Load more data
                          </button>
                          {isFetching ? (
                            <p>
                              {`Loading data... ${startDate.format(
                                process.env.REACT_APP_DATE_FORMAT_TF
                              )}`}
                            </p>
                          ) : (
                            <p>
                              {`Data loaded to: ${startDate.format(
                                process.env.REACT_APP_DATE_FORMAT_TF
                              )}`}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
