import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchView extends Component {
  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.number,
    onChange: PropTypes.func
  };

  render() {
    const { value, onChange, size, placeholder } = this.props;
    return (
      <div className={size ? `col-md-${size}` : 'col-md-6'}>
        <input
          className="form-control"
          placeholder={placeholder || 'Search for any text or data'}
          type="text"
          value={value}
          onChange={event => onChange(event.target.value)}
        />
      </div>
    );
  }
}

export default SearchView;
