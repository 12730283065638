import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';
import Foldable from '../elements/foldable';
import Header from '../elements/header';

class BlockchainsTableView extends Component {
  static propTypes = {
    foldableBlocks: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape),
    totalAssets: PropTypes.shape()
  };

  render() {
    const { foldableBlocks, headers, totalAssets } = this.props;
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map(header => (
              <Header
                key={header.key}
                name={header.name}
                className="text-center"
                {...header.options}
              />
            ))}
          </tr>
        </thead>
        <Foldable blocks={foldableBlocks} />
        <tbody>
          <tr className="info total">
            <td colSpan={3}>
              <strong>Total Assets</strong>
            </td>
            <td className="text-right">
              {Utils.formatToMaxDigit(totalAssets.balanceEur, 0)}
            </td>
            <td className="text-right">{`${totalAssets.value.toFixed(1)}%`}</td>
            {/* <td className="text-right">
            {totalAssets.change24hPercentage.toFixed(2)}
          </td> */}
            <td colSpan={4} />
          </tr>
        </tbody>
      </table>
    );
  }
}

export default BlockchainsTableView;
