import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BalancesLogsTableView from './balances-logs-table-view';

export default class BalancesLogsTable extends Component {
  static propTypes = {
    balances: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { balances } = this.props;

    const headers = [
      {
        name: 'Date',
        key: 'date',
        options: { className: 'text-left' }
      },
      {
        name: 'Account',
        key: 'account',
        options: { className: 'text-left' }
      },
      { name: 'Label', key: 'label', options: { className: 'text-left' } },
      {
        name: 'Instrument name',
        key: 'instrumentName',
        options: { className: 'text-center' }
      },
      {
        name: 'Instrument symbol',
        key: 'symbol',
        options: { className: 'text-center' }
      },
      {
        name: 'Blockchain',
        key: 'blockchain',
        options: { className: 'text-center' }
      },
      {
        name: 'Amount',
        key: 'amount',
        options: { className: 'text-right' }
      },
      {
        name: 'Last update UTC',
        key: 'lastUpdate',
        options: { className: 'text-right' }
      }
    ];

    return <BalancesLogsTableView balances={balances} headers={headers} />;
  }
}
