import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchView from './search-view';

class Search extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    size: PropTypes.number,
    placeholder: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      searchValue: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(searchValue) {
    const { onChange } = this.props;
    this.setState({ searchValue });
    onChange(searchValue);
  }

  render() {
    const { size, placeholder } = this.props;
    const { searchValue } = this.state;
    return (
      <SearchView
        onChange={this.onChange}
        value={searchValue}
        size={size}
        placeholder={placeholder}
      />
    );
  }
}

export default Search;
