import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Foldable from '../elements/foldable';
import Header from '../elements/header';
import EditAccountModal from '../../_modals/edit-manual-balance';
import RemoveAccountModal from '../../_modals/remove-account';
import BalanceEURChartModal from '../../_modals/balance-eur-chart-modal';
import Utils from '../../../services/utils';

class AccountsTableView extends Component {
  static propTypes = {
    foldableBlocks: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape),
    isEditModalOpen: PropTypes.bool,
    isRemoveModalOpen: PropTypes.bool,
    dataToEdit: PropTypes.shape(),
    closeEditModal: PropTypes.func,
    closeRemoveModal: PropTypes.func,
    openRemoveModal: PropTypes.func,
    startLooping: PropTypes.func,
    totalAssets: PropTypes.shape(),
    isBalanceEURChartModalOpen: PropTypes.bool,
    openBalanceEurChartModal: PropTypes.func,
    closeBalanceEURChartModal: PropTypes.func,
    selectedAccountToChartModal: PropTypes.string,
    selectedExchangeCode: PropTypes.string
  };

  render() {
    const {
      foldableBlocks,
      headers,
      isEditModalOpen,
      isRemoveModalOpen,
      dataToEdit,
      closeEditModal,
      closeRemoveModal,
      openRemoveModal,
      totalAssets,
      startLooping,
      isBalanceEURChartModalOpen,
      closeBalanceEURChartModal,
      selectedAccountToChartModal,
      selectedExchangeCode
    } = this.props;
    return (
      <>
        {isEditModalOpen ? (
          <EditAccountModal
            startLooping={startLooping}
            dataToEdit={dataToEdit}
            close={() => closeEditModal()}
            openRemoveModal={() => openRemoveModal()}
          />
        ) : null}
        {isRemoveModalOpen ? (
          <RemoveAccountModal
            startLooping={startLooping}
            dataToRemove={dataToEdit}
            close={() => closeRemoveModal()}
            closeEdit={() => closeEditModal()}
          />
        ) : null}
        {isBalanceEURChartModalOpen ? (
          <BalanceEURChartModal
            close={() => closeBalanceEURChartModal()}
            selectedAccount={selectedAccountToChartModal}
            selectedExchangeCode={selectedExchangeCode}
          />
        ) : null}
        <table className="table table-striped">
          <thead>
            <tr>
              {headers.map(header => (
                <Header
                  key={header.key}
                  name={header.name}
                  className="text-center"
                  {...header.options}
                />
              ))}
            </tr>
          </thead>
          <Foldable blocks={foldableBlocks} />
          <tbody>
            <tr className="info total">
              <td colSpan={3}>
                <strong>Total Assets</strong>
              </td>
              <td className="text-right">
                {Utils.formatToMaxDigit(totalAssets.balanceEur, 0)}
              </td>
              <td className="text-right">
                {`${totalAssets.value.toFixed(1)}%`}
              </td>
              {/* <td className="text-right">
              {dataset.header.change24hPercentage.toFixed(2)}
              </td> */}
              <td colSpan={5} />
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

export default AccountsTableView;
