import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TransactionHistoryTableView from './transaction-history-table-view';

export default class TransactionHistoryTable extends Component {
  static propTypes = {
    transactions: PropTypes.arrayOf(PropTypes.shape())
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isEdit: false,
      modalTransaction: null
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(obj) {
    if (obj.isEdit) {
      this.setState({
        isEdit: true,
        isModalOpen: true,
        modalTransaction: obj.transaction
      });
    } else {
      this.setState({
        isEdit: false,
        isModalOpen: true,
        modalTransaction: obj.transaction
      });
    }
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      isEdit: false,
      modalTransaction: null
    });
  }

  render() {
    const { transactions } = this.props;
    const { isModalOpen, isEdit, modalTransaction } = this.state;

    const headers = [
      // { name: 'Transaction ID', key: 'transactionId' },
      { name: 'Date and time UTC', key: 'dateAndTime' },
      {
        name: 'Exchange',
        key: 'exchangeName',
        options: { className: 'text-left' }
      },
      {
        name: 'Amount_1',
        key: 'amount_1',
        options: { className: 'text-right' }
      },
      {
        name: 'Ccy_1',
        key: 'currency_1',
        options: { className: 'text-left' }
      },
      {
        name: 'Amount_2',
        key: 'amount_2',
        options: { className: 'text-right' }
      },
      {
        name: 'Ccy_2',
        key: 'currency_2',
        options: { className: 'text-left' }
      },
      {
        name: 'Price',
        key: 'price'
      },
      {
        name: 'Pair',
        key: 'instrumentName'
      },
      {
        name: 'Side',
        key: 'side'
      }
      // {
      //   name: 'Amount Fee',
      //   key: 'amount_fee'
      // },
      // {
      //   name: 'Currency Fee',
      //   key: 'currency_fee'
      // },
      // {
      //   name: 'Info',
      //   key: 'info'
      // }
    ];
    return (
      <TransactionHistoryTableView
        headers={headers}
        data={transactions}
        openModal={this.openModal}
        closeModal={this.closeModal}
        isModalOpen={isModalOpen}
        isEdit={isEdit}
        modalTransaction={modalTransaction}
      />
    );
  }
}
