import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';

export default class FinancialChangesTableRow extends Component {
  static propTypes = {
    name: PropTypes.string,
    valueEur: PropTypes.number,
    change24h: PropTypes.number,
    change48h: PropTypes.number,
    change7d: PropTypes.number,
    change30d: PropTypes.number,
    changeMTD: PropTypes.number,
    changeYTD: PropTypes.number,
    change1y: PropTypes.number,
    change3yAY: PropTypes.number,
    change5yAY: PropTypes.number
  };

  static renderChangingValue(value) {
    if (value === Infinity || value === null) {
      return <td className="text-right text-na">n.a.</td>;
    }

    if (value !== null && value !== undefined) {
      let className =
        value > 0 ? 'text-right text-navy' : 'text-right text-danger';
      if (value === 0 || Utils.formatToMaxDigit(value, 5) === '0') {
        className = 'text-right';
      }

      let iconClass = value > 0 ? 'fa fa-level-up' : 'fa fa-level-down';
      if (value === 0 || Utils.formatToMaxDigit(value, 5) === '0') {
        iconClass = '';
      }

      return (
        <td className={className}>
          <i className={iconClass} />
          {`${value.toFixed(1)}%`}
        </td>
      );
    }

    return <td className="text-right text-na" />;
  }

  render() {
    const {
      name,
      valueEur,
      change24h,
      change48h,
      change7d,
      change30d,
      changeMTD,
      changeYTD,
      change1y,
      change3yAY,
      change5yAY
    } = this.props;
    return (
      <tr>
        <td>{name}</td>
        <td className="text-right">
          {Utils.formatChangesTableValue(name, valueEur)}
        </td>
        {FinancialChangesTableRow.renderChangingValue(change24h)}
        {FinancialChangesTableRow.renderChangingValue(change48h)}
        {FinancialChangesTableRow.renderChangingValue(change7d)}
        {FinancialChangesTableRow.renderChangingValue(change30d)}
        {FinancialChangesTableRow.renderChangingValue(changeMTD)}
        {FinancialChangesTableRow.renderChangingValue(changeYTD)}
        {FinancialChangesTableRow.renderChangingValue(change1y)}
        {FinancialChangesTableRow.renderChangingValue(change3yAY)}
        {FinancialChangesTableRow.renderChangingValue(change5yAY)}
      </tr>
    );
  }
}
