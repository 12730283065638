import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BalancesLogView from './balances-log-view';

export default class BalancesLog extends Component {
  static propTypes = {
    getBalancesLog: PropTypes.func,
    clearBalancesLog: PropTypes.func,
    balances: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      end: moment(),
      start: moment()
        .subtract(1, 'day')
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0),
      searchValue: '',
      showCounter: 1
    };
    this.setSearchValue = this.setSearchValue.bind(this);
    this.setTrim = this.setTrim.bind(this);
    this.loadMoreData = this.loadMoreData.bind(this);
  }

  componentDidMount() {
    const { getBalancesLog } = this.props;
    const { start, end } = this.state;
    getBalancesLog(moment(start).add(1, 'd'), end);
  }

  componentWillUnmount() {
    const { clearBalancesLog } = this.props;
    clearBalancesLog();
  }

  setSearchValue(newSearchValue) {
    const { searchValue } = this.state;
    if (newSearchValue === '' && searchValue === '') {
      return;
    }
    this.setState({ searchValue: newSearchValue });
  }

  setTrim() {
    this.setState(prevState => ({ showCounter: prevState.showCounter + 1 }));
  }

  loadMoreData() {
    const { getBalancesLog } = this.props;
    const { start } = this.state;
    const newStart = moment(start).subtract(1, 'd');
    getBalancesLog(newStart, moment(start));
    this.setState({ start: newStart });
  }

  filterBalances(balances) {
    const { searchValue, showCounter } = this.state;

    let filteredBalances = [...balances];

    // 1. Apply searched keywords to sort
    if (searchValue !== '' && searchValue.length >= 3) {
      // define keywords
      const keywords = searchValue.split(' ');

      // Filter addresses where all keywords are present
      filteredBalances = filteredBalances.filter(balance => {
        let match = true;
        let contains = true;
        keywords.forEach(word => {
          if (match) {
            contains = false;
            const values = Object.values(balance).map(
              value => value && value.toString().toUpperCase()
            );
            values.forEach(value => {
              if (value && value.includes(word.toUpperCase())) contains = true;
            });
            if (!contains) {
              match = false;
            }
          }
        });
        return match;
      });
    }

    filteredBalances = filteredBalances.sort((a, b) => {
      if (moment(a.lastUpdate).isBefore(moment(b.lastUpdate))) {
        return 1;
      }
      if (moment(b.lastUpdate).isBefore(moment(a.lastUpdate))) {
        return -1;
      }
      return 0;
    });

    // Number of rows to show
    const NUM_OF_ROWS = 20;

    // Button flag
    let shouldShowButton = false;

    // 4. If too much cut the size
    if (filteredBalances.length > NUM_OF_ROWS * showCounter) {
      filteredBalances = filteredBalances.slice(0, NUM_OF_ROWS * showCounter);
      shouldShowButton = true;
    }

    return { filteredBalances, shouldShowButton };
  }

  render() {
    const { start } = this.state;
    const { balances, isFetching } = this.props;
    const { filteredBalances, shouldShowButton } =
      this.filterBalances(balances);

    return (
      <BalancesLogView
        startDate={start}
        balances={filteredBalances}
        isFetching={isFetching}
        shouldShowButton={shouldShowButton}
        setSearchValue={this.setSearchValue}
        setTrim={this.setTrim}
        loadMoreData={this.loadMoreData}
      />
    );
  }
}
