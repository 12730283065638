import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BalancesLogsTableRow from './balances-logs-table-row';
import Header from '../elements/header';

export default class BalancesLogsTableView extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape()),
    balances: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { headers, balances } = this.props;
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map(header => (
              <Header
                key={header.key}
                name={header.name}
                className="text-center"
                {...header.options}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {balances.map(balance => (
            <BalancesLogsTableRow key={balance.id} {...balance} />
          ))}
        </tbody>
      </table>
    );
  }
}
