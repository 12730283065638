import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TransactionHistoryModalView from './transaction-history-modal-view';

class TransactionHistoryModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    transaction: PropTypes.shape(),
    isEdit: PropTypes.bool,
    editTransaction: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      note: '',
      strategy: ''
    };

    this.setValues = this.setValues.bind(this);
  }

  componentDidMount() {
    const { transaction, isEdit } = this.props;
    this.setState({
      note: transaction.note ? transaction.note : '',
      strategy: transaction.strategy ? transaction.strategy : '',
      isEdit
    });
  }

  setValues(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    const { close, transaction, editTransaction } = this.props;
    const { note, strategy, isEdit } = this.state;

    return (
      <TransactionHistoryModalView
        editTransaction={editTransaction}
        transaction={transaction}
        note={note}
        strategy={strategy}
        setValues={this.setValues}
        close={close}
        isEdit={isEdit}
      />
    );
  }
}

export default TransactionHistoryModal;
