import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';

class TransactionHistoryModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    note: PropTypes.string,
    strategy: PropTypes.string,
    setValues: PropTypes.func,
    isEdit: PropTypes.bool,
    transaction: PropTypes.shape(),
    editTransaction: PropTypes.func
  };

  render() {
    const {
      close,
      note,
      strategy,
      setValues,
      isEdit,
      transaction,
      editTransaction
    } = this.props;
    return (
      <Modal close={close}>
        <div className="modal-header">
          <button type="button" className="close" onClick={close}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-info modal-icon" />
          <h4 className="modal-title">
            {isEdit ? 'Edit info for transaction' : 'Add info to transaction'}
          </h4>
        </div>
        <div className="modal-body">
          <div className="modal-input">
            <p>Note</p>
            <input
              className="form-control"
              autoFocus // eslint-disable-line jsx-a11y/no-autofocus
              type="text"
              style={{ width: 200, marginTop: 10 }}
              placeholder="Note..."
              value={note}
              name="note"
              onChange={e => setValues(e.target.name, e.target.value)}
            />
          </div>
          <div className="modal-input">
            <p>Strategy</p>
            <input
              className="form-control"
              type="text"
              style={{ width: 200, marginTop: 10 }}
              placeholder="Strategy..."
              value={strategy}
              name="strategy"
              onChange={e => setValues(e.target.name, e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-white" onClick={close}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              close();
              editTransaction(transaction.id, { note, strategy });
            }}
          >
            {isEdit ? 'Edit info' : 'Add info'}
          </button>
        </div>
      </Modal>
    );
  }
}

export default TransactionHistoryModalView;
