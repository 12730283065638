import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Utils from '../../../services/utils';

class SourceInstrument extends React.Component {
  static propTypes = {
    transaction: PropTypes.shape(),
    openModal: PropTypes.func
  };

  renderInfo(transaction) {
    const { openModal } = this.props;
    if (transaction.note || transaction.strategy) {
      return (
        <button
          type="button"
          onClick={() => openModal({ transaction, isEdit: true })}
          className="btn btn-info btn-xs"
          style={{ width: 25 }}
        >
          <i className="fa fa-info" style={{ margin: 0, padding: 0 }} />
        </button>
      );
    }
    return (
      <button
        type="button"
        onClick={() => openModal({ transaction, isEdit: false })}
        className="btn btn-primary btn-xs"
        style={{ width: 25 }}
      >
        <i className="fa fa-plus" style={{ margin: 0, padding: 0 }} />
      </button>
    );
  }

  render() {
    const { transaction } = this.props;
    let leftColor = '';
    let rightColor = '';
    let leftMultiplier = 0;
    let rightMultiplier = 0;

    if (transaction && transaction.side) {
      switch (transaction.side.toLowerCase()) {
        case 'buy':
        case 'staking':
        case 'open long':
        case 'close short':
        case 'delivery buy':
          leftColor = 'text-green';
          rightColor = 'text-red';
          leftMultiplier = 1;
          rightMultiplier = -1;
          break;
        default:
          leftColor = 'text-red';
          rightColor = 'text-green';
          leftMultiplier = -1;
          rightMultiplier = 1;
          break;
      }
    }

    return (
      <tr>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {moment(transaction.transactionDate).utc().format('HHmm') === '0000'
            ? moment(transaction.transactionDate)
                .utc()
                .subtract(1, 'd')
                .format('YYYY-MM-DD kk:mm')
            : moment(transaction.transactionDate)
                .utc()
                .format('YYYY-MM-DD HH:mm')}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <strong>{transaction.exchangeName}</strong>
        </td>
        <td
          className={`text-right ${leftColor}`}
          style={{ verticalAlign: 'middle' }}
        >
          {Utils.formatToMaxDigit(
            Math.abs(transaction.amountLeft) * leftMultiplier
          )}
        </td>
        <td
          className={`text-left ${leftColor}`}
          style={{ verticalAlign: 'middle' }}
        >
          {transaction &&
            transaction.currencyLeft &&
            transaction.currencyLeft.toUpperCase()}
        </td>
        <td
          className={`text-right ${rightColor}`}
          style={{ verticalAlign: 'middle' }}
        >
          {Utils.formatToMaxDigit(
            Math.abs(transaction.amountRight) * rightMultiplier
          )}
        </td>
        <td
          className={`text-left ${rightColor}`}
          style={{ verticalAlign: 'middle' }}
        >
          {transaction &&
            transaction.currencyRight &&
            transaction.currencyRight.toUpperCase()}
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {Utils.formatToMaxDigit(transaction.price)}
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {transaction && transaction.currencyLeft && transaction.currencyRight
            ? `${transaction.currencyLeft.toUpperCase()}/${transaction.currencyRight.toUpperCase()}`
            : ''}
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {transaction.side}
        </td>
        {/* <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {transaction.txId}
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {transaction.amountFee}
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {transaction.currencyFee}
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {this.renderInfo(transaction)}
        </td> */}
      </tr>
    );
  }
}

export default SourceInstrument;
