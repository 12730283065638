import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TransactionHistoryView from './transaction-history-view';

export default class TransactionHistory extends Component {
  static propTypes = {
    getTransactionHistory: PropTypes.func,
    clearTransactionHistory: PropTypes.func,
    isFetching: PropTypes.bool,
    transactions: PropTypes.arrayOf(PropTypes.shape()),
    metadata: PropTypes.arrayOf(PropTypes.shape()),
    accounts: PropTypes.arrayOf(PropTypes.shape()),
    isCSVFetching: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      end: moment(),
      start: moment()
        .subtract(1, 'day')
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0),
      searchValue: '',
      isTransfersModalOpen: false,
      isTransactionsModalOpen: false,
      isFtxReportModalOpen: false,
      isMarginTransactionsModalOpen: false,
      isOptionTransactionsModalOpen: false,
      showCounter: 1
    };
    this.setSearchValue = this.setSearchValue.bind(this);
    this.openTransfersModal = this.openTransfersModal.bind(this);
    this.closeTransfersModal = this.closeTransfersModal.bind(this);
    this.openTransactionsModal = this.openTransactionsModal.bind(this);
    this.closeTransactionsModal = this.closeTransactionsModal.bind(this);
    this.openMarginTransactionsModal =
      this.openMarginTransactionsModal.bind(this);
    this.closeMarginTransactionsModal =
      this.closeMarginTransactionsModal.bind(this);
    this.openOptionTransactionsModal =
      this.openOptionTransactionsModal.bind(this);
    this.closeOptionTransactionsModal =
      this.closeOptionTransactionsModal.bind(this);
    this.openFtxReportModal = this.openFtxReportModal.bind(this);
    this.closeFtxReportModal = this.closeFtxReportModal.bind(this);
    this.setTrim = this.setTrim.bind(this);
    this.loadMoreData = this.loadMoreData.bind(this);
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  componentDidMount() {
    const { getTransactionHistory } = this.props;
    const { start, end } = this.state;
    getTransactionHistory(moment(start).add(1, 'd'), end);
  }

  componentWillUnmount() {
    const { clearTransactionHistory } = this.props;
    clearTransactionHistory();
  }

  // ----------------------------------------------
  // Data manipulation functions with state or prop
  // ----------------------------------------------

  setSearchValue(newSearchValue) {
    const { searchValue } = this.state;
    if (newSearchValue === '' && searchValue === '') {
      return;
    }
    this.setState({ searchValue: newSearchValue });
  }

  setTrim() {
    this.setState(prevState => ({ showCounter: prevState.showCounter + 1 }));
  }

  loadMoreData() {
    const { getTransactionHistory } = this.props;
    const { start } = this.state;
    const newStart = moment(start);
    newStart.subtract(7, 'day');
    getTransactionHistory(
      moment(newStart).add(1, 'd'),
      moment(start).add(1, 'd')
    );
    this.setState({ start: newStart });
  }

  filterTransactions(transactions) {
    const { searchValue, showCounter } = this.state;

    let filteredTransactions = [...transactions];

    // filter out borrow and lending transactions
    filteredTransactions = filteredTransactions.filter(
      transaction => !['lending', 'borrow'].includes(transaction.side)
    );

    // 1. Apply searched keywords to sort
    if (searchValue !== '') {
      // define keywords
      const keywords = searchValue.split(' ');

      // Filter addresses where all keywords are present
      filteredTransactions = filteredTransactions.filter(transaction => {
        let match = true;
        let contains = true;
        keywords.forEach(word => {
          if (match) {
            contains = false;
            const values = Object.values(transaction).map(
              value => value && value.toString().toUpperCase()
            );
            values.forEach(value => {
              if (value && value.includes(word.toUpperCase())) contains = true;
            });
            if (!contains) {
              match = false;
            }
          }
        });
        return match;
      });
    }

    // Number of rows to show
    const NUM_OF_ROWS = 20;

    // Button flag
    let shouldShowButton = false;

    // 4. If too much cut the size
    if (filteredTransactions.length > NUM_OF_ROWS * showCounter) {
      filteredTransactions = filteredTransactions.slice(
        0,
        NUM_OF_ROWS * showCounter
      );
      shouldShowButton = true;
    }

    return { filteredTransactions, shouldShowButton };
  }

  // ---------------
  // Modal functions
  // ---------------

  openTransfersModal() {
    this.setState({ isTransfersModalOpen: true });
  }

  closeTransfersModal() {
    this.setState({ isTransfersModalOpen: false });
  }

  openTransactionsModal() {
    this.setState({ isTransactionsModalOpen: true });
  }

  closeTransactionsModal() {
    this.setState({ isTransactionsModalOpen: false });
  }

  openMarginTransactionsModal() {
    this.setState({ isMarginTransactionsModalOpen: true });
  }

  closeMarginTransactionsModal() {
    this.setState({ isMarginTransactionsModalOpen: false });
  }

  openOptionTransactionsModal() {
    this.setState({ isOptionTransactionsModalOpen: true });
  }

  closeOptionTransactionsModal() {
    this.setState({ isOptionTransactionsModalOpen: false });
  }

  openFtxReportModal() {
    this.setState({ isFtxReportModalOpen: true });
  }

  closeFtxReportModal() {
    this.setState({ isFtxReportModalOpen: false });
  }

  // ------
  // Render
  // ------

  render() {
    const {
      isTransfersModalOpen,
      isTransactionsModalOpen,
      isFtxReportModalOpen,
      isMarginTransactionsModalOpen,
      isOptionTransactionsModalOpen,
      start
    } = this.state;
    const { transactions, isFetching, metadata, accounts, isCSVFetching } =
      this.props;

    const { filteredTransactions, shouldShowButton } =
      this.filterTransactions(transactions);

    return (
      <TransactionHistoryView
        isTransfersModalOpen={isTransfersModalOpen}
        openTransfersModal={this.openTransfersModal}
        closeTransfersModal={this.closeTransfersModal}
        isTransactionsModalOpen={isTransactionsModalOpen}
        openTransactionsModal={this.openTransactionsModal}
        closeTransactionsModal={this.closeTransactionsModal}
        isMarginTransactionsModalOpen={isMarginTransactionsModalOpen}
        openMarginTransactionsModal={this.openMarginTransactionsModal}
        closeMarginTransactionsModal={this.closeMarginTransactionsModal}
        isOptionTransactionsModalOpen={isOptionTransactionsModalOpen}
        openOptionTransactionsModal={this.openOptionTransactionsModal}
        closeOptionTransactionsModal={this.closeOptionTransactionsModal}
        isFtxReportModalOpen={isFtxReportModalOpen}
        openFtxReportModal={this.openFtxReportModal}
        closeFtxReportModal={this.closeFtxReportModal}
        transactions={filteredTransactions}
        setSearchValue={this.setSearchValue}
        setTrim={this.setTrim}
        shouldShowButton={shouldShowButton}
        isFetching={isFetching}
        metadata={metadata}
        accounts={accounts}
        loadMoreData={this.loadMoreData}
        startDate={start}
        isCSVFetching={isCSVFetching}
      />
    );
  }
}
