import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PortfoliosTableRow from './portfolios-table-row';
import Utils from '../../../services/utils';
import { THIRD_HEADER_COLOR } from '../../../constants/colors';
import Foldable from '../elements/foldable';

export default class PortfoliosCurrencyTypesPanel extends Component {
  static propTypes = {
    header: PropTypes.shape(),
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    style: PropTypes.shape(),
    groupByType: PropTypes.string
  };

  getHeader = (header, style, positionType) =>
    header && header.currencyType !== undefined ? (
      <tr
        key={`${positionType}${header.underlyingSymbol}${header.currencyType}`}
        style={{ ...style, background: THIRD_HEADER_COLOR }}
      >
        <td colSpan={6}>
          <strong>{header.currencyType || 'unknown'}</strong>
        </td>
        <td className="text-right">
          {Utils.formatToMaxDigit(header.balanceEur, 0)}
        </td>
        <td className="text-right">{`${header.value.toFixed(1)}%`}</td>
        <td className="text-right">
          {Utils.formatToMaxDigit(header.exposureEur, 0)}
        </td>
        <td className="text-right">{`${header.exposure.toFixed(1)}%`}</td>
        <td className="text-right">{`${header.cryptoExposure.toFixed(1)}%`}</td>
        <td colSpan={2} />
      </tr>
    ) : (
      <React.Fragment
        key={`${positionType}${header.underlyingSymbol}${header.currencyType}`}
      />
    );

  render() {
    const { header, dataSet, style, groupByType } = this.props;
    const positionType = dataSet[0].isAlgo ? 'Algo' : 'Manual';

    const headerKey = `${positionType}${header.underlyingSymbol}${header.currencyType}`;

    const currencyTypeHeader =
      header && header.currencyType !== undefined ? (
        <>
          <td colSpan={6}>
            <strong>{header.currencyType || 'unknown'}</strong>
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(header.balanceEur, 0)}
          </td>
          <td className="text-right">{`${header.value.toFixed(1)}%`}</td>
          <td className="text-right">
            {Utils.formatToMaxDigit(header.exposureEur, 0)}
          </td>
          <td className="text-right">{`${header.exposure.toFixed(1)}%`}</td>
          <td className="text-right">
            {`${header.cryptoExposure.toFixed(1)}%`}
          </td>
          <td colSpan={2} />
        </>
      ) : null;

    const sortedDataSet = dataSet.sort((a, b) => {
      if (groupByType === 'account') {
        if (a.account?.toLowerCase() > b.account?.toLowerCase()) return 1;
        if (a.account?.toLowerCase() < b.account?.toLowerCase()) return -1;

        if (a.subaccount?.toLowerCase() > b.subaccount?.toLowerCase()) return 1;
        if (a.subaccount?.toLowerCase() < b.subaccount?.toLowerCase())
          return -1;
      } else {
        if (a.instrument?.toLowerCase() > b.instrument?.toLowerCase()) return 1;
        if (a.instrument?.toLowerCase() < b.instrument?.toLowerCase())
          return -1;

        if (a.account?.toLowerCase() > b.account?.toLowerCase()) return 1;
        if (a.account?.toLowerCase() < b.account?.toLowerCase()) return -1;
      }
      if (a.label?.toLowerCase() > b.label?.toLowerCase()) return 1;
      if (a.label?.toLowerCase() < b.label?.toLowerCase()) return -1;

      return -1;
    });

    const defineGroupData = (groupType, type, obj) => {
      const {
        amount,
        balanceEur,
        unconfirmedBalanceEur,
        value,
        unconfirmedExposureEur,
        exposureEur,
        exposure,
        cryptoExposure,
        account
      } = obj;
      return {
        type:
          groupType === 'instrument' && account === 'Rocket Pool staking'
            ? `RP ${type}`
            : type,
        total: amount || 0,
        balanceEur: unconfirmedBalanceEur || balanceEur || 0,
        assets: value || 0,
        netExp: unconfirmedExposureEur || exposureEur || 0,
        netExpPerc: exposure || 0,
        netCryptoExp: cryptoExposure || 0,
        objects: []
      };
    };

    // Grouping the rows and calculating the sum of the groups
    const groupedRows = sortedDataSet.reduce((acc, obj) => {
      let groupBy = {
        type: obj.account,
        total: 0,
        balanceEur: 0,
        assets: 0,
        netExp: 0,
        netExpPerc: 0,
        netCryptoExp: 0,
        objects: []
      };
      switch (groupByType) {
        case 'account':
          groupBy = defineGroupData('account', obj.account, obj);
          break;
        case 'instrument':
          groupBy = defineGroupData('instrument', obj.instrument, obj);
          break;
        default:
          groupBy = { ...groupBy };
          break;
      }
      if (!acc[groupBy.type]) {
        acc[groupBy.type] = {
          total: 0,
          balanceEur: 0,
          assets: 0,
          netExp: 0,
          netExpPerc: 0,
          netCryptoExp: 0,
          objects: []
        };
      }
      acc[groupBy.type].total += groupBy.total;
      acc[groupBy.type].balanceEur += groupBy.balanceEur;
      acc[groupBy.type].assets += groupBy.assets;
      acc[groupBy.type].netExp += groupBy.netExp;
      acc[groupBy.type].netExpPerc += groupBy.netExpPerc;
      acc[groupBy.type].netCryptoExp += groupBy.netCryptoExp;
      acc[groupBy.type].objects.push(obj);
      return acc;
    }, {});

    const body = [];
    Object.entries(groupedRows).forEach(([key, group]) => {
      group.objects.forEach((data, idx) => {
        body.push(
          <PortfoliosTableRow
            key={data.id}
            account={data.account}
            subaccount={data.subaccount}
            instrument={data.instrument}
            amount={data.unconfirmedAmount}
            balanceEur={data.unconfirmedBalanceEur}
            value={data.value}
            change24hPercentage={data.change24hPercentage}
            exposureEur={data.unconfirmedExposureEur}
            exposure={data.exposure}
            cryptoExposure={data.cryptoExposure}
            lastUpdate={data.sourceTime}
            label={data.label}
            idx={idx}
            style={style}
          />
        );
      });
      // New row is inserted at end of each group which displays the sum of the group
      body.push(
        <tr key={`total_${key}`}>
          <td style={{ background: '#DCDCDC' }} colSpan={5}>
            <b>{`Total - ${key}`}</b>
          </td>
          <td style={{ background: '#DCDCDC' }} className="text-right">
            {group.total ? Utils.formatAmountMaxDigit(group.total, '') : null}
          </td>
          <td style={{ background: '#DCDCDC' }} className="text-right">
            {Utils.formatToMaxDigit(group.balanceEur, 0)}
          </td>
          <td style={{ background: '#DCDCDC' }} className="text-right">
            {`${group.assets.toFixed(1)}%`}
          </td>
          <td style={{ background: '#DCDCDC' }} className="text-right">
            {Utils.formatToMaxDigit(group.netExp, 0)}
          </td>
          <td style={{ background: '#DCDCDC' }} className="text-right">
            {`${group.netExpPerc.toFixed(1)}%`}
          </td>
          <td style={{ background: '#DCDCDC' }} className="text-right">
            {`${group.netCryptoExp.toFixed(1)}%`}
          </td>
          <td style={{ background: '#DCDCDC' }} colSpan={2} />
        </tr>
      );
    });

    const blocks = [{ key: headerKey, header: currencyTypeHeader, body }];
    return (
      <Foldable
        blocks={blocks}
        headerColor={THIRD_HEADER_COLOR}
        isFirst={false}
        style={style}
      />
    );
  }
}
