/* globals document, localStorage, window */
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import Utils from '../../services/utils';
import TestingLabel from '../testing-label';

class Navigation extends React.Component {
  static propTypes = {
    location: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired
  };

  static deleteToken() {
    localStorage.removeItem('authToken');
    window.location.replace('/login');
  }

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      menuOpen: true
    };

    this.handleDropdown = this.handleDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.closeDropdown);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeDropdown);
  }

  handleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  activeRoute(routeName) {
    const { location } = this.props;
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  secondLevelActive(routeName) {
    const { location } = this.props;
    return location.pathname.indexOf(routeName) > -1
      ? 'nav nav-second-level collapse in'
      : 'nav nav-second-level collapse';
  }

  closeDropdown() {
    if (!document.activeElement.className.includes('dropdown-toggle')) {
      this.setState({
        dropdownOpen: false
      });
    }
  }

  render() {
    const { user } = this.props;
    const { menuOpen, dropdownOpen } = this.state;

    return (
      <nav className="navbar-default navbar-static-side">
        <button
          className={`menu-toogle menu-toogle-${menuOpen ? 'open' : 'close'}`}
          type="button"
          onClick={() => {
            document.body.classList.toggle('mini-navbar');
            this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
          }}
        >
          <i className={`fa fa-${menuOpen ? 'arrow-left' : 'arrow-right'}`} />
        </button>
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element">
              <span />
              <button
                className="dropdown-toggle btn-transparent"
                onClick={() => this.handleDropdown()}
                type="button"
              >
                <span className="clear">
                  <span className="block m-t-xs">
                    <div>
                      <TestingLabel />
                      <strong className="font-bold-green bigger-font-size">
                        {Utils.getFoundationType()}
                      </strong>
                    </div>
                  </span>
                  <span className="block m-t-xs">
                    <strong className="font-bold">{user.name}</strong>
                  </span>
                  <span className="text-muted text-xs block">
                    {user.email}
                    <b className="caret" />
                  </span>
                </span>
              </button>
              <ul
                className="dropdown-menu animated fadeInRight m-t-xs"
                style={dropdownOpen ? { display: 'block' } : {}}
              >
                <li>
                  <button
                    className="log-out-button btn-transparent"
                    onClick={Navigation.deleteToken}
                    type="button"
                  >
                    <i className="fa fa-sign-out" />
                    Log out
                  </button>
                </li>
              </ul>
            </div>
            <div className="logo-element">
              <div className="font-bold-green">
                {Utils.getFoundationType('S')}
              </div>
              <div>CM+</div>
            </div>
          </li>
          <li className={this.activeRoute('/watchlist')}>
            <Link to="/watchlist">
              <i className="fa fa-list" />
              <span className="nav-label">Watchlist</span>
            </Link>
          </li>
          <li className={this.activeRoute('/chart')}>
            <Link to="/chart">
              <i className="fa fa-bar-chart" />
              <span className="nav-label">Chart</span>
            </Link>
          </li>
          <li className={this.activeRoute('/crypto-addresses')}>
            <Link to="/crypto-addresses">
              <i className="fa fa-btc" />
              <span className="nav-label">Crypto addresses</span>
            </Link>
          </li>
          <li className={this.activeRoute('/account-portfolios')}>
            <Link to="/account-portfolios">
              <i className="fa fa-th-large" />
              <span className="nav-label">Accounts & portfolio</span>
            </Link>
          </li>
          <li className={this.activeRoute('/financial-statement')}>
            <Link to="/financial-statement">
              <i className="fa fa-money" />
              <span className="nav-label">Financial statement</span>
            </Link>
          </li>
          <li className={this.activeRoute('/settings')}>
            <Link to="/settings">
              <i className="fa fa-cog" />
              <span className="nav-label">Settings</span>
            </Link>
          </li>
          <li className={this.activeRoute('/transaction-history')}>
            <Link to="/transaction-history">
              <i className="fa fa-dollar" />
              <span className="nav-label">Transaction history</span>
            </Link>
          </li>
          <li className={this.activeRoute('/balances-log')}>
            <Link to="/balances-log">
              <i className="fa fa-balance-scale" />
              <span className="nav-label">Balances log</span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(Navigation);
