import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';

export default class LiabilitiesTableRow extends Component {
  static propTypes = {
    style: PropTypes.shape(),
    name: PropTypes.string,
    portfolioValue: PropTypes.number,
    portfolioValueEur: PropTypes.number,
    netAssetValue: PropTypes.number
  };

  render() {
    const { style, name, portfolioValue, portfolioValueEur, netAssetValue } =
      this.props;

    let cellStyle = {};
    if (portfolioValueEur === 0) {
      cellStyle = { color: '#b9b9b9' };
    }

    return (
      <tr style={style}>
        <td style={cellStyle} className="text-left">
          {name}
        </td>
        <td style={cellStyle} className="text-right">
          {Utils.formatToMaxDigit(portfolioValueEur, 0)}
        </td>
        <td style={cellStyle} className="text-right">
          {`${portfolioValue.toFixed(1)}%`}
        </td>
        <td style={cellStyle} className="text-right">
          {`${netAssetValue.toFixed(1)}%`}
        </td>
        <td colSpan={1} />
      </tr>
    );
  }
}
