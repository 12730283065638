/* eslint-disable indent, operator-linebreak */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DateWithTooltip from '../../date-with-tooltip';

class Instrument extends React.Component {
  static propTypes = {
    style: PropTypes.shape({}),
    name: PropTypes.string,
    // bid: PropTypes.number,
    // ask: PropTypes.number,
    // lastTrade: PropTypes.number,
    dailyChangePercent: PropTypes.number,
    // dailyHigh: PropTypes.number,
    // dailyLow: PropTypes.number,
    // dailyVolumePercent: PropTypes.number,
    lastUpdate: PropTypes.string,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    // isFiat: PropTypes.bool,
    // spread: PropTypes.string,
    rate: PropTypes.number
  };

  static renderChangingValue(value) {
    if (value !== null && value !== undefined) {
      let className = value > 0 ? 'text-navy' : 'text-danger';
      if (value === 0) {
        className = '';
      }

      let iconClass = value > 0 ? 'fa fa-level-up' : 'fa fa-level-down';
      if (value === 0) {
        iconClass = '';
      }

      return (
        <td className={className}>
          <i className={iconClass} />
          {`${value.toFixed(1)}%`}
        </td>
      );
    }

    return <td className="text-center text-na">n.a.</td>;
  }

  render() {
    const {
      style,
      name,
      // bid,
      // ask,
      // lastTrade,
      dailyChangePercent,
      // spread,
      // dailyHigh,
      // dailyLow,
      // dailyVolumePercent,
      lastUpdate,
      isError,
      errorMessage,
      // isFiat,
      rate
    } = this.props;
    return (
      <tr style={style}>
        <td>
          <strong>{name}</strong>
        </td>
        {/* <td className="text-center">{Utils.formatToMaxDigit(bid, 6)}</td>
        <td className="text-center">{Utils.formatToMaxDigit(ask, 6)}</td>
        <td className="text-center">{!isFiat ? spread : 'na'}</td> */}
        <td className="text-right">{rate.toFixed(2)}</td>
        {/* <td className="text-center">{Utils.formatToMaxDigit(lastTrade, 6)}</td> */}
        {Instrument.renderChangingValue(dailyChangePercent)}
        {/* <td className="text-center">{Utils.formatToMaxDigit(dailyHigh, 6)}</td>
        <td className="text-center">{Utils.formatToMaxDigit(dailyLow, 6)}</td>
        {Instrument.renderChangingValue(dailyVolumePercent)} */}

        <td>
          <DateWithTooltip
            elementType="div"
            date={moment(lastUpdate).utc()}
            name="instrument-test-date"
            className="text-right"
            innerValue={
              isError
                ? {
                    props: {
                      'data-toggle': 'tooltip',
                      title: errorMessage,
                      className: 'fa fa-exclamation-triangle text-danger'
                    },
                    elementType: 'i'
                  }
                : null
            }
          />
        </td>
      </tr>
    );
  }
}

export default Instrument;
