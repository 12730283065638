import { connect } from 'react-redux';
import { clearBalancesLog } from '../../ducks/balances-log';
import { getBalancesLog } from '../../ducks/request';
import BalancesLog from './balances-log';

const mapStateToProps = state => {
  const { balancesLog } = state;
  return {
    balances: balancesLog.data,
    isFetching: balancesLog.isFetching
  };
};

export default connect(mapStateToProps, { getBalancesLog, clearBalancesLog })(
  BalancesLog
);
