import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Utils from '../../../services/utils';
import DateWithTooltip from '../../date-with-tooltip/date-with-tooltip';
import { TABLE_ODD_ROWS } from '../../../constants/colors';

class InstrumentsTableRow extends Component {
  static propTypes = {
    style: PropTypes.shape(),
    instrument: PropTypes.string,
    amount: PropTypes.number,
    balanceEur: PropTypes.number,
    value: PropTypes.number,
    // change24hPercentage: PropTypes.number,
    exposureEur: PropTypes.number,
    exposure: PropTypes.number,
    lastUpdate: PropTypes.string,
    label: PropTypes.string,
    subaccount: PropTypes.string,
    account: PropTypes.string,
    idx: PropTypes.number
  };

  render() {
    const {
      style,
      instrument,
      amount,
      account,
      subaccount,
      balanceEur,
      value,
      // change24hPercentage,
      exposureEur,
      exposure,
      lastUpdate,
      label,
      idx
    } = this.props;

    return (
      <tr
        style={{
          ...style,
          background: idx === 1 || idx % 2 !== 0 ? TABLE_ODD_ROWS : 'white'
        }}
      >
        <td className="text-right" />
        <td className="text-left">{account || ''}</td>
        <td className="text-right">{subaccount || ''}</td>
        <td className="text-right">{label}</td>
        <td className="text-right">{instrument && instrument.toUpperCase()}</td>
        <td className="text-right">
          {Utils.formatAmountMaxDigit(amount, instrument)}
        </td>
        <td className="text-right">{Utils.formatToMaxDigit(balanceEur, 0)}</td>
        <td className="text-right">{`${value.toFixed(1)}%`}</td>
        {/* <td className="text-right">{`${change24hPercentage.toFixed(2)}%`}</td> */}
        <td className="text-right">{Utils.formatToMaxDigit(exposureEur, 0)}</td>
        <td className="text-right">{`${exposure.toFixed(1)}%`}</td>
        <DateWithTooltip
          date={moment(lastUpdate).utc()}
          elementType="td"
          className="text-right"
        />
      </tr>
    );
  }
}

export default InstrumentsTableRow;
