import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';

class Address extends Component {
  static propTypes = {
    date: PropTypes.string,
    account: PropTypes.string,
    label: PropTypes.string,
    instrumentName: PropTypes.string,
    instrumentSymbol: PropTypes.string,
    blockchain: PropTypes.string,
    amount: PropTypes.number,
    unconfirmedAmount: PropTypes.number,
    lastUpdate: PropTypes.string
  };

  render() {
    const {
      date,
      account,
      label,
      instrumentName,
      instrumentSymbol,
      blockchain,
      amount,
      unconfirmedAmount,
      lastUpdate
    } = this.props;
    return (
      <tr>
        <td className="text-left">
          {moment(date).utc().format('HHmm') === '0000'
            ? moment(date).utc().subtract(1, 'd').format('YYYY-MM-DD kk:mm:ss')
            : moment(date).utc().format('YYYY-MM-DD HH:mm:ss')}
        </td>
        <td className="">{account}</td>
        <td className="">{label}</td>
        <td className="text-center">{instrumentName}</td>
        <td className="text-center">
          {instrumentSymbol && instrumentSymbol.toUpperCase()}
        </td>
        <td className="text-center">{blockchain}</td>
        <td className="text-right">
          {Utils.formatToMaxDigit(
            account !== 'Private wallet' ? unconfirmedAmount : amount,
            6
          )}
        </td>
        <td className="text-right">
          {moment(lastUpdate).utc().format('HHmm') === '0000'
            ? moment(lastUpdate)
                .utc()
                .subtract(1, 'd')
                .format('YYYY-MM-DD kk:mm:ss')
            : moment(lastUpdate).utc().format('YYYY-MM-DD HH:mm:ss')}
        </td>
      </tr>
    );
  }
}

export default Address;
