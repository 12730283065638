/* globals document window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ViewHeader from '../../components/_view-header';
import Search from '../../components/_search';
import BalancesLogsTable from '../../components/_table/balances-logs-table';

export default class BalancesLogView extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    balances: PropTypes.arrayOf(PropTypes.shape()),
    shouldShowButton: PropTypes.bool,
    setTrim: PropTypes.func,
    setSearchValue: PropTypes.func,
    loadMoreData: PropTypes.func,
    startDate: PropTypes.shape()
  };

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { shouldShowButton, setTrim } = this.props;
    let contHeight = document.getElementById('scroll-container');
    contHeight = contHeight && contHeight.clientHeight;
    if (
      shouldShowButton &&
      window.scrollY + window.innerHeight > contHeight + 200
    ) {
      setTrim();
    }
  }

  render() {
    const {
      isFetching,
      balances,
      shouldShowButton,
      setSearchValue,
      loadMoreData,
      startDate
    } = this.props;
    return (
      <div>
        <ViewHeader title="Balances Log" />
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <div className="panel-body">
                      <div className="row">
                        <div className="grid-2">
                          <div>
                            <Search
                              onChange={e => {
                                if (e.length < 3) {
                                  setSearchValue('');
                                } else {
                                  setSearchValue(e);
                                }
                              }}
                              size={8}
                            />
                            {isFetching ? (
                              <div
                                style={{
                                  display: 'inline-block',
                                  marginLeft: 20,
                                  verticalAlign: 'middle'
                                }}
                              >
                                <div
                                  className="sk-spinner sk-spinner-chasing-dots"
                                  style={{ width: 25, height: 25 }}
                                >
                                  <div className="sk-dot1" />
                                  <div className="sk-dot2" />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div id="scroll-container">
                        <BalancesLogsTable balances={balances} />
                      </div>
                      {shouldShowButton ? null : (
                        <div className="load-more-info">
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled={isFetching}
                            onClick={() => {
                              loadMoreData();
                            }}
                          >
                            Load more data
                          </button>
                          {isFetching ? (
                            <p>
                              {`Loading data... ${startDate.format(
                                process.env.REACT_APP_DATE_FORMAT_TF
                              )}`}
                            </p>
                          ) : (
                            <p>
                              {`Data loaded to: ${startDate.format(
                                process.env.REACT_APP_DATE_FORMAT_TF
                              )}`}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
