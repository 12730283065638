import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TransactionHistoryTableRow from './transaction-history-table-row';
import Header from '../elements/header';
import TransactionHistoryModal from '../../_modals/transaction-history-modal';

class TransactionHistoryTableView extends Component {
  static propTypes = {
    openModal: PropTypes.func,
    headers: PropTypes.arrayOf(PropTypes.any),
    data: PropTypes.arrayOf(PropTypes.any),
    isModalOpen: PropTypes.bool,
    modalTransaction: PropTypes.shape(),
    closeModal: PropTypes.func,
    isEdit: PropTypes.bool
  };

  render() {
    const {
      headers,
      data,
      openModal,
      isModalOpen,
      modalTransaction,
      closeModal,
      isEdit
    } = this.props;

    /**
     * The order of rendering:
     *
     * 1. Wrap everything into a table
     * 2. Render the headers in <thead>
     * 3. Render the sortedDataset in <SourceIntrument> elements
     */
    return (
      <>
        {isModalOpen ? (
          <TransactionHistoryModal
            close={closeModal}
            transaction={modalTransaction}
            isEdit={isEdit}
          />
        ) : null}
        <table className="table table-striped">
          <thead>
            <tr>
              {headers.map(header => (
                <Header
                  key={header.key}
                  name={header.name}
                  className="text-center"
                  {...header.options}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(transaction => (
              <TransactionHistoryTableRow
                openModal={openModal}
                key={transaction.id}
                transaction={transaction}
              />
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default TransactionHistoryTableView;
