import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';
import PortfoliosTableView from './portfolios-table-view';
import PortfoliosTableInstrumentsPanel from './portfolios-table-instruments-panel';

class PortfoliosTable extends Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.shape()),
    symbols: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.array])
      )
    )
  };

  // Group accounts by manual or algo type
  static groupByPortfolios(portfolios) {
    const groupedPortfolios = [];
    portfolios.forEach(acc => {
      if (
        acc.amount !== 0 ||
        (acc.account === 'Swissquote' && acc.balanceEur !== 0)
      ) {
        const group = groupedPortfolios.find(
          g => g.header.isAlgo === acc.isAlgo
        );
        if (group) {
          group.rows.push(acc);
          group.header.balanceEur +=
            acc.unconfirmedBalanceEur || acc.balanceEur;
          group.header.value += acc.value;
          group.header.change24hPercentage += acc.change24hPercentage;
          group.header.exposureEur +=
            acc.unconfirmedExposureEur || acc.exposureEur;
          group.header.exposure += acc.exposure;
          group.header.cryptoExposure += acc.cryptoExposure;
        } else {
          groupedPortfolios.push({
            header: {
              positionType: acc.isAlgo ? 'Algo' : 'Manual',
              isAlgo: acc.isAlgo,
              balanceEur: acc.unconfirmedBalanceEur || acc.balanceEur,
              value: acc.value,
              change24hPercentage: acc.change24hPercentage,
              exposureEur: acc.unconfirmedExposuasdreEur || acc.exposureEur,
              exposure: acc.exposure,
              cryptoExposure: acc.cryptoExposure
            },
            rows: [acc]
          });
        }
      }
    });
    return groupedPortfolios;
  }

  static groupByUnderlyingSymbol(instruments, symbols) {
    // create dictionary from map
    const symbolsDict =
      symbols?.reduce((dict, [key, value]) => {
        return { ...dict, [key]: value };
      }, {}) || {};

    const groupedUnderlyingSymbols = [];
    instruments.forEach(symbol => {
      // compare symbol with group of symbols and use the group to display it
      let symbolToMatch = symbol.underlyingSymbol.toLowerCase();
      const symbolKeys = Object.keys(symbolsDict);
      let i = 0;
      while (i < symbolKeys.length) {
        const key = symbolKeys[i];
        if (symbolsDict[key].includes(symbolToMatch)) {
          symbolToMatch = key;
          break;
        }
        i += 1;
      }

      if (symbol.amount !== 0) {
        const group = groupedUnderlyingSymbols.find(
          g => g.header.underlyingSymbol === symbolToMatch
        );

        if (group) {
          group.rows.push(symbol);
          group.header.balanceEur +=
            symbol.unconfirmedBalanceEur || symbol.balanceEur;
          group.header.value += symbol.value;
          group.header.change24hPercentage += symbol.change24hPercentage;
          group.header.exposureEur +=
            symbol.unconfirmedExposureEur || symbol.exposureEur;
          group.header.exposure += symbol.exposure;
          group.header.cryptoExposure += symbol.cryptoExposure;
        } else {
          const newGroup = {
            header: {
              instrument: symbol.instrument,
              underlyingSymbol: symbolToMatch,
              amount: symbol.amount,
              account: symbol.account,
              label: symbol.label,
              positionType: symbol.isAlgo ? 'Algo' : 'Manual',
              subaccount: symbol.subaccount,
              balanceEur: symbol.unconfirmedBalanceEur || symbol.balanceEur,
              value: symbol.value,
              change24hPercentage: symbol.change24hPercentage,
              exposureEur: symbol.unconfirmedExposureEur || symbol.exposureEur,
              exposure: symbol.exposure,
              cryptoExposure: symbol.cryptoExposure
            },
            rows: [symbol]
          };
          groupedUnderlyingSymbols.push(newGroup);
        }
      }
    });

    return groupedUnderlyingSymbols;
  }

  constructor(props) {
    super(props);

    this.state = {
      groupByType: 'account'
    };
  }

  render() {
    const { accounts, symbols } = this.props;
    const { groupByType } = this.state;

    // add new UPL records and edit the old ones
    const extendedRecords = Utils.extendBalanceRecordWithUPL(accounts);

    // Groups by portfolios
    const groupedPortfolios =
      PortfoliosTable.groupByPortfolios(extendedRecords);

    const totalAssets = {
      balanceEur: 0,
      value: 0,
      exposureEur: 0,
      exposure: 0
    };

    groupedPortfolios.forEach(group => {
      totalAssets.balanceEur += group.header.balanceEur;
      totalAssets.value += group.header.value;
      totalAssets.exposure += group.header.exposure;
      totalAssets.exposureEur += group.header.exposureEur;
    });

    // Create foldable blocks
    const foldableBlocks = [];
    groupedPortfolios.forEach(dataset => {
      const groupedUnderlyingSymbols = PortfoliosTable.groupByUnderlyingSymbol(
        dataset.rows,
        symbols
      );

      // The header should NEVER be wrapped in <tr> tag
      const header = (
        <>
          <td colSpan={6}>
            <strong>{dataset.header.positionType}</strong>
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.balanceEur, 0)}
          </td>
          <td className="text-right">
            {`${dataset.header.value.toFixed(1)}%`}
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.exposureEur, 0)}
          </td>
          <td className="text-right">
            {`${
              dataset.header.exposure && dataset.header.exposure.toFixed(1)
            }%`}
          </td>
          <td className="text-right">
            {`${
              dataset.header.cryptoExposure &&
              dataset.header.cryptoExposure.toFixed(1)
            }%`}
          </td>
          <td colSpan={2} />
        </>
      );

      // The elements given in the body should ALWAYS add
      // the ```style``` prop on their own render to their wrapping DOM element
      //
      // eg:
      // <tr style={style}>
      //    ...element comes here...
      // </tr>

      const body = groupedUnderlyingSymbols
        .sort((a, b) =>
          (a.header.underlyingSymbol &&
            a.header.underlyingSymbol.toUpperCase()) >
          (b.header.underlyingSymbol && b.header.underlyingSymbol.toUpperCase())
            ? 1
            : -1
        )
        .map(data => {
          const positionType = data.rows[0].isAlgo ? 'Algo' : 'Manual';
          return (
            <PortfoliosTableInstrumentsPanel
              key={`${positionType}${data.header.underlyingSymbol}`}
              header={data.header}
              dataSet={data.rows}
              groupByType={groupByType}
            />
          );
        });

      // Add the header and the body as an object to the blocks array
      foldableBlocks.push({
        key: dataset.header.positionType,
        header,
        body
      });
    });

    const headers = [
      {
        name: 'Position type',
        key: 'positionType',
        options: { className: 'text-left' }
      },
      {
        name: 'Account',
        key: 'account',
        options: { className: 'text-left' },
        onClick: () => this.setState({ groupByType: 'account' }),
        selected: groupByType === 'account'
      },
      {
        name: 'Subaccount',
        key: 'subaccount',
        options: { className: 'text-right' }
      },
      {
        name: 'Label',
        key: 'label',
        options: { className: 'text-right' }
      },
      {
        name: 'Instrument',
        key: 'instrument',
        options: { className: 'text-right' },
        onClick: () => this.setState({ groupByType: 'instrument' }),
        selected: groupByType === 'instrument'
      },
      {
        name: 'Amount',
        key: 'amount',
        options: { className: 'text-right' }
      },
      {
        name: `Balance ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'balanceEur',
        options: { className: 'text-right' }
      },
      {
        name: '% of assets',
        key: 'percentageOfAssets',
        options: { className: 'text-right' }
      },
      {
        name: `Net exposure ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'netExposureEur',
        options: { className: 'text-right' }
      },
      {
        name: 'Net Exposure %',
        key: 'netExposurePercentage',
        options: { className: 'text-right' }
      },
      {
        name: 'Net Crypto Exposure %',
        key: 'netCryptoExposurePercentage',
        options: { className: 'text-right' }
      },
      {
        name: 'Last Update UTC',
        key: 'lastUpdate',
        options: { className: 'text-right' }
      }
    ];

    return (
      <PortfoliosTableView
        headers={headers}
        foldableBlocks={foldableBlocks}
        totalAssets={totalAssets}
      />
    );
  }
}

export default PortfoliosTable;
