import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';

class PortfolioChangesTableRow extends Component {
  static propTypes = {
    isNavShareBenchmark: PropTypes.bool,
    name: PropTypes.string,
    valueEur: PropTypes.number,
    navPercentage: PropTypes.number,
    change24h: PropTypes.number,
    change48h: PropTypes.number,
    change7d: PropTypes.number,
    change30d: PropTypes.number,
    changeMTD: PropTypes.number,
    changeYTD: PropTypes.number,
    change1y: PropTypes.number,
    change3yAY: PropTypes.number,
    change5yAY: PropTypes.number
  };

  static renderChangingValue(value) {
    if (value === Infinity || value === null) {
      return <td className="text-right text-na">n.a.</td>;
    }

    if (value !== null && value !== undefined) {
      let className =
        value > 0 ? 'text-right text-navy' : 'text-right text-danger';
      if (value === 0) {
        className = 'text-right';
      }

      let iconClass = value > 0 ? 'fa fa-level-up' : 'fa fa-level-down';
      if (value === 0) {
        iconClass = '';
      }

      return (
        <td className={className}>
          <i className={iconClass} />
          {`${value.toFixed(1)}%`}
        </td>
      );
    }

    return <td className="text-right text-na" />;
  }

  render() {
    const {
      isNavShareBenchmark,
      name,
      valueEur,
      navPercentage,
      change24h,
      change48h,
      change7d,
      change30d,
      changeMTD,
      changeYTD,
      change1y,
      change3yAY,
      change5yAY
    } = this.props;

    const perc = navPercentage ? `${navPercentage.toFixed(1)}%` : '0.0%';
    const navSharePerc =
      navPercentage && navPercentage >= 0.05
        ? `${navPercentage.toFixed(1)}%`
        : '';
    return (
      <tr>
        <td style={{ width: '180px' }}>{name}</td>
        <td className="text-right">
          {Utils.formatChangesTableValue(name, valueEur)}
        </td>
        {isNavShareBenchmark ? (
          <td className="text-right">{navSharePerc}</td>
        ) : (
          <td className="text-right">{perc}</td>
        )}
        {PortfolioChangesTableRow.renderChangingValue(change24h)}
        {PortfolioChangesTableRow.renderChangingValue(change48h)}
        {PortfolioChangesTableRow.renderChangingValue(change7d)}
        {PortfolioChangesTableRow.renderChangingValue(change30d)}
        {PortfolioChangesTableRow.renderChangingValue(changeMTD)}
        {PortfolioChangesTableRow.renderChangingValue(changeYTD)}
        {PortfolioChangesTableRow.renderChangingValue(change1y)}
        {PortfolioChangesTableRow.renderChangingValue(change3yAY)}
        {PortfolioChangesTableRow.renderChangingValue(change5yAY)}
      </tr>
    );
  }
}

export default PortfolioChangesTableRow;
